.nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #d3d3d3;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

    }

    a.home-link {
        &:after {
            content: 'HOME';
          }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
        &:after {
          content: 'PROJECTS';
        }
      }

    a.active {
      svg {
        color: #d3d3d3;
      }
    }

    ul {
        position: absolute;
        margin-top: 220px !important;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;
    
        li {
          a {
            padding: 1px 0;
            display: block;
            font-size: 15px;
            line-height: 16px;
            color: #4d4d4e;
          }
    
          &:hover {
            .anchor-icon-g, .anchor-icon-l {
                opacity: 1 !important;
                color: #d3d3d3;
            }
          }
        }
      }
  }
}

.hamburger-icon, .close-icon {
  display: none;
}


@media screen and (max-width: 1100px) {
  .nav-bar { 
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;

    ul, nav {
      display: none;
    }

    nav {
      width: 100%;
      top: 0;
      left: 0;
      background: #181818;
      height: 100%;
      margin: 0;
      z-index: 2;
      position: fixed;

      &.mobile-show {
        display: block;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10PX;
      }

      a svg {
        opacity: 1 !important;
      }

      ul {
        margin-top: 60% !important;
        transform: scale(1.5);
      }
    }

    .hamburger-icon,
    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        display: block;
    }

    .logo {
        opacity: 0;
    }
  }
}

@media screen and (max-width: 415px) { 
    .nav-bar { 
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;
        
        ul, nav {
            display: none;
        }
        
        nav {
            width: 100%;
            top: 0;
            left: 0;
            background: #181818;
            height: 100%;
            margin: 0;
            z-index: 2;
            position: fixed;
        
            &.mobile-show {
                display: block;
            }
        
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        
            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10PX;
            }
        
            a svg {
                opacity: 1 !important;
            }
        
            ul {
                margin-top: 50% !important;
                transform: scale(1.5);
            }
        }
    }
}