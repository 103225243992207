.project-card {
    background: #d3d3d3;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    max-width: 900px;
    margin: 20px auto;
    transition: transform 0.2s ease;

    &:hover {
        transform: translateY(-10px);
    }

    .project-title {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #181818;
    }

    .project-description {
        color: #000000 !important;
        animation: none !important;
        font-size: 1rem;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .project-credits {
        color: #000000;
        font-size: small;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .project-button {
        background-color: #181818;
        color: #d3d3d3;
        padding: 10px 20px;
        text-decoration: none;
        font-size: 1.3rem;
        border-radius: 5px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #4d4d4e;
        }
    }
}
