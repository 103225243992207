.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    z-index: 1;


    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #d3d3d3;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #d3d3d3;
      font-size: 18px;
      position: absolute;
      margin-top: -42px;
      margin-left: 250px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      opacity: 0;
      margin-left: 15px;
      margin-right: -3px;
      margin-bottom: -10px;
      width: 50px;
      height: 75px;
      animation: rotateIn 1s linear both;
      animation-delay: 1s;
      z-index: 0;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .greeting-text {
    margin-bottom: -60px;
  }

  .flat-button {
    color: #d3d3d3;
    font-size: 13px;
    font-weight: 400;
    width: 124px;
    height: 20px;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #d3d3d3;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #d3d3d3;
      color: #333;
    }
  }

  .headshot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6.5%;
    margin-right: -40%;
  }
  
  .headshot {
    width: 512px;
    height: 512px; 
    border-radius: 50%;
    border: 6px solid #000;
    object-fit: cover;
  }
}

@media screen and (max-width: 1100px) {
    .tags,
    .home-page h1:before,
    .home-page h1:after {
        display: none;
    } 

    .home-page .text-zone {
        position:    absolute;
        left:        50%;
        top:         50%;
        width:       400px;
        height:      100px;
        margin-left: -185px;
        margin-top:  520px;
    }

    .home-page .name-text {
        text-align: center;
        vertical-align: middle;
        line-height: 5px;
    }

    .home-page .flat-button {
        margin: 0;
        margin-top: 145px;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .home-page h2 {
        text-align: center;
        vertical-align: middle;
        line-height: 10px;  
    }

    .greeting-text {
        display: none;
    }

    .headshot-container {
        max-width: 50%;
        max-height: 50%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin-bottom: 100px;
      }
      
      .headshot {
        margin-bottom:-350px;
      }
}

@media screen and (max-width: 415px) { 
    .home-page .text-zone {
        position:    absolute;
        left:        50%;
        top:         50%;
        width:       400px;
        height:      100px;
        margin-left: -190px;
        margin-top:  420px;
    }

    .headshot-container {
        transform: scale(.85) !important;
        max-width: 2%;
        max-height: 50%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 48%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin-bottom: 100px;
      }
}


